import $ from 'jquery'
const { get } = $

function hamburger_nav() {
  // Add class au clique
  $('.js-hamburger-btn').click(function () {
    $('.layout-sidebar').addClass('navbar--open')
  })

  // supprime class au clique hors menu et hors btn humburger
  $('body').click(function (cible) {
    if ($(cible.target).closest('.layout-sidebar').length || $(cible.target).closest('.js-hamburger-btn').length) {
      return
    } else {
      $('.layout-sidebar').removeClass('navbar--open')
    }
  })
}

function table_dropdown_function() {
  $(document).on('click', '.js-table-dropdown tbody tr:not(.table-dropdown-item)', function (cible) {
    if (!$(cible.target).closest('.td-actions').length && $(this).next('.table-dropdown-item').length) {
      $(this).next('.table-dropdown-item').toggle().siblings('.table-dropdown-item').hide()
      $(this).not('.js-module-open-modal').toggleClass('table-tr--active').siblings().removeClass('table-tr--active')

      var link = $(this).data('href')

      if (typeof link !== 'undefined') {
        $.ajax({
          type: 'GET',
          url: link
        })
      }
    }
  })

  $('.table-booking-container tr:not(.table-dropdown-item)').click(function () {
    if ($('.table-booking-container thead').css('display') == 'none') {
      tr_target = $(this).offset().top

      setTimeout(function () {
        $('html, body').animate({ scrollTop: tr_target }, 300)
      }, 100)
    }
  })

  /* menu mobile */
  $('.js-table-drop-menu-mobile').click(function () {
    var target = $(this).attr('menu-mobile-target')

    $('.td-actions-menu').each(function () {
      if ($(this).attr('menu-mobile-target') == target) {
        var menu = $(this).find('.dropdown-menu').html()
        $('.modal-menu-mobile-actions').html(menu)
        $('.modal-menu-mobile-container').css('display', 'flex').hide().fadeIn()
        $('html').css('overflow-y', 'hidden')
      }
    })
  })
  /* close click cancel */
  $('.modal-menu-mobile-container .modal-menu-cancel button').click(function () {
    $('.modal-menu-mobile-container').hide()
    $('html').css('overflow-y', 'auto')
  })
  /* close click action */
  $('.modal-menu-mobile-container .modal-menu-mobile-actions').on('click', 'a', function () {
    $('.modal-menu-mobile-container').hide()
    $('html').css('overflow-y', 'auto')
  })
}

function tab() {
  $('.js-ajax-target').on('click', '.js-tab', function () {
    $(this).addClass('tab--active').siblings().removeClass('tab--active')
    var dataLink = $(this).attr('data-tab')
    if (dataLink == 'practical-informations') {
      slist('sortlist')
      activeAttributes()
    }

    $(this)
      .parents('.js-ajax-target')
      .find('.js-tab-article-content')
      .each(function () {
        var dataArticle = $(this).attr('data-tab')
        console.log(dataArticle, dataLink)
        if (dataArticle == dataLink) {
          $(this).addClass('tab-article-content--active').siblings().removeClass('tab-article-content')
        } else {
          $(this).removeClass('tab-article-content--active')
        }
      })

    if (dataLink == 'messages') {
      scrollLastMessageIntoView()
    }

    /*table_border_cell();*/
  })
}

function tablist() {
  // Accessibilitéc: assets/tablist_accessibility.js
  $('.js-tablist__btn').on('click', function () {
    $(this).addClass('tab--active')
    $('.js-tablist__btn').not(this).removeClass('tab--active')
  })
}

function boxvisu_modal() {
  /* Close btn */
  // $(".js-boxvisu-modal-container").on("click", ".js-boxvisu-close-btn", function(){
  //     $('.js-boxvisu-modal-container').fadeOut();
  //     $("html, body").css("overflow-y","auto");
  // });
  /* Reinit visibility boxvisu */
  // if ($('.js-boxvisu-modal-container').length && $(".js-boxvisu-modal").length && $(".js-boxvisu-modal").css("position") != "absolute") {
  //     $('.js-boxvisu-modal-container').css("display", "block");
  //     $("html, body").css("overflow-y","auto");
  // }
  // else if ($('.js-boxvisu-modal-container').length && $(".js-boxvisu-modal").length && $(".js-boxvisu-modal").css("position") == "absolute") {
  //     $('.js-boxvisu-modal-container').css("display", "none");
  //     $("html, body").css("overflow-y","auto");
  // }
}

function table_boxvisu_function() {
  var currentRequest = null
  $('.js-table-with-boxvisu tbody tr').each(function () {
    /* Charge contenu en ajax on click */
    $(this).click(function (cible) {
      /* si le clique n'est pas sur un td d'actions */
      if (!$(cible.target).closest('.td-actions').length) {
        var link = $(this).data('href')

        $(this).addClass('table-tr--active').siblings().removeClass('table-tr--active')
        /* transition pour vue desktop */
        if ($('.js-boxvisu-modal').length && $('.boxvisu__container').css('display') != 'none') {
          $('.boxvisu-loader.fa-spinner').remove()
          $('.js-boxvisu-with-table').fadeOut(300, function () {
            $('.boxvisu__container').append("<i class='fas fa-spinner boxvisu-loader'></i>")
            currentRequest = $.ajax({
              type: 'GET',
              url: link,
              beforeSend: function () {
                if (currentRequest != null) {
                  currentRequest.abort()
                }
              }
            }).done(function () {
              $('.boxvisu-loader.fa-spinner').remove()
              $('.js-boxvisu-with-table').fadeIn(300)
            })
          })
        } else if ($('.js-boxvisu-modal').length && $('.boxvisu__container').css('display') == 'none') {
          /* Affiche le overview__module sur mobile */
          var btn_data = $(this).attr('data-module')
          /* Overflow html hidden pour éviter les multiples barres de scroll */
          $('html, body').css('overflow-y', 'hidden')
          /* affiche le module associé */
          $('.js-overview-module__content').each(function () {
            var module_data = $(this).attr('data-module')

            if (module_data == btn_data) {
              $(this).parents('.js-modules-container').fadeIn()
              $('.js-overview-module__content').not(this).removeClass('overview-module__content--open')
              var box = $(this).find('.overview-module__box')

              $(this).fadeIn(300, function () {
                $(this).append("<div class='overview-module__loader loader__container'><i class='loader__icon fas fa-spinner'></i></div>")
                $(this).addClass('overview-module__content--open')
                currentRequest = $.ajax({
                  type: 'GET',
                  url: link,
                  beforeSend: function () {
                    if (currentRequest != null) {
                      currentRequest.abort()
                    }
                  }
                }).done(function () {
                  $('.loader__container').remove()
                })
              })

              $(this).parents('.js-modules-container').find('.js-overview-module__content').not(this).fadeOut()
              scrollLastMessageIntoView()
            }
          })
        }
      }
    })
  })
}

function table_to_single() {
  /* Add class active and ajax content */
  $('.js-table-to-single tbody tr').each(function () {
    $(this).click(function (cible) {
      /* si le clique n'est pas sur un td d'actions */
      if (!$(cible.target).closest('.td-actions').length) {
        var link = $(this).data('href')

        window.location.href = link
      }
    })
  })
}

function fadeOut_alert() {
  if ($('.js-alert').length) {
    setTimeout(function () {
      $('.alert').animate({ top: '-15px' }, 500)
      $('.alert')
        .fadeOut({ queue: false, duration: 500, easing: 'linear' })
        .promise()
        .done(function () {
          $(this).remove()
        })
    }, 4000)
  }
}

function fixed_maxHeight_div() {
  if ($('.js-fixed-div').length) {
    if ($('.js-fixed-div').css('position') != 'fixed') {
      var parentPaddingTop = $('.js-fixed-div').parent().css('padding-top')
      var parentPaddingBottom = $('.js-fixed-div').parent().css('padding-Bottom')
      var windowH = $(window).height()

      var topValue = parseInt(parentPaddingTop)
      var heightValue = parseInt(windowH) - parseInt(parentPaddingTop) - parseInt(parentPaddingBottom)

      $('.js-fixed-div').css({ height: heightValue, top: topValue })
    } else {
      $('.js-fixed-div').css({ height: 'inherit', top: '0' })
    }
  }
}

function slide_tabs() {
  $('.js-tabs-slider').each(function () {
    $(this).children('ul').width('auto')

    var x, down

    $(this)
      .children('ul')
      .on('touchstart', function (e) {
        tabsWidth = 0
        $(this)
          .find('li')
          .each(function () {
            tabsWidth += $(this).outerWidth(true)
          })
        $(this).width(tabsWidth)
        containerWidth = $(this).parent('.js-tabs-slider').width()

        stop = parseInt(containerWidth) - parseInt(tabsWidth)

        /* Si la slidebar est plus grande que son parent */
        if (stop < -1) {
          down = true
        }
        x = e.touches[0].pageX
        tabDelta = new Array()
      })

    $(this)
      .children('ul')
      .on('touchmove', function (e) {
        if (down) {
          var newX = e.touches[0].pageX
          var delta = -(parseInt(x) - parseInt(newX))

          tabDelta.push(delta)
          tabDelta.reverse()

          var obj = $(this)
          var transformMatrix =
            obj.css('-webkit-transform') || obj.css('-moz-transform') || obj.css('-ms-transform') || obj.css('-o-transform') || obj.css('transform')
          var matrix = transformMatrix.replace(/[^0-9\-.,]/g, '').split(',')
          var valueMatrix = matrix[12] || matrix[4] //translate x

          deltaValue = parseInt(tabDelta[0]) - parseInt(tabDelta[1])

          var newDelta = parseInt(valueMatrix) + parseInt(deltaValue)
          if (newDelta <= 0 && newDelta > stop) {
            $(this).css('transform', 'matrix(1, 0, 0, 1, ' + newDelta + ', 0)')
          } else if (newDelta > 0) {
            $(this).css('transform', 'matrix(1, 0, 0, 1, 0, 0)')
          } else if (newDelta <= stop) {
            $(this).css('transform', 'matrix(1, 0, 0, 1, ' + stop + ', 0)')
          }
        }
      })

    $(this)
      .children('ul')
      .on('touchend', function (e) {
        down = false
      })
  })

  $(window).on('resize', function () {
    $('.js-tabs-slider').children('ul').css('transform', 'matrix(1, 0, 0, 1, 0, 0)')
  })
}

function dropdown_toggle() {
  $('.table-app-user-container tr').mouseleave(function () {
    $(this).find('.show').removeClass('show')
  })

  $('.party-boxvisu-guest-select, .title-language-select').mouseleave(function () {
    $(this).find('.selectize-dropdown').hide()
  })

  $(document).on('mouseleave', '.dropdown-menu:not(.static)', function () {
    $(this).removeClass('show')
    $(this).parent().removeClass('show')
  })

  $(document).on('mouseleave', '.td-actions-menu', function (el) {
    var parent = $(this).parent('tr')
    if (!$(el.target).closest(parent)) {
      $(this).find('.show').removeClass('show')
    }
  })
}

function form_general() {
  /* click btn "open form" -> close modal boxvisu */
  $(document).on('click', '.js-btn-open-form-modal', function () {
    if ($('.js-boxvisu-modal').length && $('.js-boxvisu-modal').css('position') == 'absolute') {
      $('.js-boxvisu-modal-container, .js-modules-container, .js-overview-module__content').fadeOut()
      $('html, body').css('overflow-y', 'hidden')
    }
  })

  /* click clos btn -> close modal */
  $(document).on('click', '.js-form-close-btn', function () {
    $('.js-form-modal__container').fadeOut()
    $('html, body').css('overflow-y', 'auto')
  })

  /* radio */
  $('.js-form-radio-container').each(function () {
    $(this).on('click', '.form__radio-model', function () {
      $(this).parents('.js-form-radio-container').find('.form__radio-model').removeClass('form-radio--checked')
      $(this).addClass('form-radio--checked')
    })
  })

  $('.js-form-radio-toggle-container').on('click', '.form__radio-model', function (cible) {
    if ($(cible.target).closest('.form__radio-model').length) {
      $(this).toggleClass('form-radio--checked')
    }
  })

  $(document).on('click', '.js-form-button', function () {
    const classesBtnLoad = 'is-load tw-pointer-events-none'
    $(this).addClass(classesBtnLoad)
    document.querySelector('.spinner') === null && $(this).append("<i class='fas fa-sync-alt spinner'></i>")
    setTimeout(() => {
      $(this).removeClass(classesBtnLoad)
      $('.form__button i').remove()
    }, 7000)
  })

  /* open/close form boxvisu */
  $('.form-modal__widget').on('click', '.js-more', function () {
    $(this).parents('.form-modal__widget').addClass('form-modal__widget--open').find('.form-modal__widget__body').slideDown()
  })

  $('.form-modal__widget').on('click', '.js-minus', function () {
    $(this).parents('.form-modal__widget').removeClass('form-modal__widget--open').find('.form-modal__widget__body').slideUp()
  })
}

function previewInputPhoto() {
  $('body').on('change', '.form__input-photo-preview input', function (event) {
    var preview = event.currentTarget.closest('.form__input-photo-preview')
    var input = $(event.currentTarget)
    var file = input[0].files[0]
    var reader = new FileReader()
    preview.classList.add('--edit-mode')
    reader.onload = function (e) {
      var image_base64 = e.target.result
      preview.style.backgroundImage = `url(${image_base64})`
    }
    reader.readAsDataURL(file)
  })
}

function preview_upload_image() {
  var preview = $('.form__upload-preview img')
  $('.upload-photo-input').change(function (event) {
    var input = $(event.currentTarget)
    var file = input[0].files[0]
    var reader = new FileReader()
    reader.onload = function (e) {
      var image_base64 = e.target.result
      preview.attr('src', image_base64)
    }
    reader.readAsDataURL(file)
  })
}

function inbox() {
  /* désactive class active sur mobile */
  if ($('.boxvisu--conversation').length && $('.boxvisu--conversation').css('display') == 'none') {
    $('.js-conversation-card').removeClass('conversation-card--active')
  }

  /* Clique -> Affichage dans overview OU redirection sur page single */
  $('.js-conversation-card').click(function (event) {
    let conversation_box = $('.boxvisu--conversation')

    if (conversation_box.length && conversation_box.css('display') != 'none') {
      $('.js-conversation-card').not(this).removeClass('conversation-card--active')
      $(this).addClass('conversation-card--active')
      $(this).removeClass('conversation-card--new-message')
    } else {
      $('.js-conversation-card').removeClass('conversation-card--active')
      event.preventDefault()
      let link = $(this).parent().attr('href')
      window.location.href = link
    }
  })

  /* FadeOut if click out of modal */
  $('body').click(function (cible) {
    if (
      !$(cible.target).closest('.js-boxvisu-modal').length &&
      !$(cible.target).closest('.js-inbox-open-guestvisu').length &&
      $('.js-inbox-open-guestvisu').length &&
      $('.js-boxvisu-modal').css('position') == 'absolute'
    ) {
      $('.js-boxvisu-modal-container').fadeOut()
      $('html, body').css('overflow-y', 'auto')
    }
  })
}

function show_hide_password() {
  $(document).on('click', '.show-hide-password', function () {
    event.preventDefault()
    var show_hide_button = $(this)
    var password_input = show_hide_button.parents('.field-btn').find('input.password')
    if (password_input.attr('type') == 'password') {
      password_input.attr('type', 'text')
      show_hide_button.html('<i class="fas fa-eye-slash"></i>')
    } else {
      password_input.attr('type', 'password')
      show_hide_button.html('<i class="fas fa-eye"></i>')
    }
  })
}

function notifs() {
  $('.js-open-notifs-container').on('click', function () {
    if ($('.notifs-wrapper').css('display') == 'none') {
      $('.notifs-wrapper').show(function () {
        $('.notifs-container').addClass('notifs-container--active')
      })
    } else {
      $('.notifs-container').removeClass('notifs-container--active')
      $('.notifs-wrapper').hide()
    }
  })

  /* close notifs container */
  $('body').click(function (cible) {
    if (
      (!$(cible.target).closest('.notifs-container--active').length &&
        !$(cible.target).closest('.notifs-container--active .notifs-container__header').length &&
        !$(cible.target).closest('.notifs-container--active .notifs-container__footer').length &&
        !$(cible.target).closest('.js-open-notifs-container').length) ||
      $(cible.target).closest('.notifs-container--active .notifs-container__body a').length
    ) {
      $('.notifs-container').removeClass('notifs-container--active')
      $('.notifs-wrapper').hide()
    }
  })
}

function toggle_accordeon(cible) {
  jQuery('.js-accordeon-toggle').click(function (cible) {
    if (!$(cible.target).closest('.prevent-accordeon').length) {
      jQuery(this).parent('.js-accordeon__item').toggleClass('accordeon__item--active').children('.js-accordeon-revealed').slideToggle(300)
      jQuery('.js-accordeon-toggle')
        .not(this)
        .parent('.js-accordeon__item')
        .removeClass('accordeon__item--active')
        .children('.js-accordeon-revealed')
        .slideUp(300)
    }
  })
}

export function checkbox() {
  // click on "checkbox" will toggle class "Done" to his main parent
  jQuery('.js-checkbox').on('click', function () {
    jQuery(this).parents('.task').toggleClass('task--done')
  })
}

function add_style_hover() {
  // Generic function to add some style hover effect
  $(document)
    .find($('.js-style-hover'))
    .on('mouseenter', function () {
      var data_primary_color_hover = $(this).data('hover-primary-color')
      var data_secondary_color_hover = $(this).data('hover-secondary-color')
      var data_tertiary_color_hover = $(this).data('hover-tertiary-color')

      $(this).find('.js-style-hover-bg-sec-to-prim').css('background', data_primary_color_hover, 'important')
      $(this).find('.js-style-hover-bg-prim-to-ter').css('background', data_tertiary_color_hover, 'important')
      $(this).find('.js-style-hover-color-prim-to-white').css('color', '#fff', 'important')
      $(this).find('.js-style-hover-color-black-to-prim').css('color', data_primary_color_hover, 'important')

      // // btn primary
      // if ($(this).hasClass(".js-style-hover-btn-bg-prim-to-ter")) {
      //     $(this).css('background', data_tertiary_color_hover, 'important');
      // }
      // // btn secondary
      // if ($(this).hasClass(".js-style-hover-btn-bg-sec-to-prim")) {
      //     $(this).css('background', data_primary_color_hover, 'important');
      // }
    })

  $(document)
    .find($('.js-style-hover'))
    .on('mouseleave', function () {
      var data_primary_color_hover = $(this).data('hover-primary-color')
      var data_secondary_color_hover = $(this).data('hover-secondary-color')
      var data_tertiary_color_hover = $(this).data('hover-tertiary-color')

      $(this).find('.js-style-hover-bg-sec-to-prim').css('background', data_secondary_color_hover, 'important')
      $(this).find('.js-style-hover-bg-prim-to-ter').css('background', data_primary_color_hover, 'important')
      $(this).find('.js-style-hover-color-prim-to-white').css('color', data_primary_color_hover, 'important')
      $(this).find('.js-style-hover-color-black-to-prim').css('color', '#182140', 'important')

      // // btn primary
      // if ($(this).hasClass(".js-style-hover-btn-bg-prim-to-ter")) {
      //     $(this).css('background', data_primary_color_hover, 'important');
      // }
      // // btn secondary
      // if ($(this).hasClass(".js-style-hover-btn-bg-sec-to-prim")) {
      //     $(this).css('background', data_secondary_color_hover, 'important');
      // }
    })

  // hover style for btn primary
  $('.js-style-hover-btn-bg-prim-to-ter').on('mouseenter', function () {
    var data_tertiary_color_hover = $(this).data('hover-tertiary-color')
    $(this).css('background', data_tertiary_color_hover, 'important')
  })

  $('.js-style-hover-btn-bg-prim-to-ter').on('mouseleave', function () {
    var data_primary_color_hover = $(this).data('hover-primary-color')
    $(this).css('background', data_primary_color_hover, 'important')
  })

  // hover style for btn secondary
  $('.js-style-hover-btn-bg-sec-to-prim').on('mouseenter', function () {
    var data_primary_color_hover = $(this).data('hover-primary-color')
    $(this).css('background', data_primary_color_hover, 'important')
  })

  $('.js-style-hover-btn-bg-sec-to-prim').on('mouseleave', function () {
    var data_secondary_color_hover = $(this).data('hover-secondary-color')
    $(this).css('background', data_secondary_color_hover, 'important')
  })

  // hover style for color text btn
  $('.js-style-hover-btn-color-prim-to-white').on('mouseenter', function () {
    $(this).css('color', '#fff', 'important')
  })

  $('.js-style-hover-btn-color-prim-to-white').on('mouseleave', function () {
    var data_primary_color_hover = $(this).data('hover-primary-color')
    $(this).css('color', data_primary_color_hover, 'important')
  })

  // hover style for link text
  $('.js-style-hover-color-link-black-to-prim').on('mouseenter', function () {
    var data_primary_color_hover = $(this).data('hover-primary-color')
    $(this).css('color', data_primary_color_hover, 'important')
  })

  $('.js-style-hover-color-link-black-to-prim').on('mouseleave', function () {
    $(this).css('color', '#182140', 'important')
  })
}

function ota_accounts() {
  $('.createHousingFromListing').on('click', function () {
    $(this).css('pointer-events', 'none').html("<i class='fas fa-spinner fa-spin'</i>")
  })
}

function openSubMenu() {
  $('.sidebar__bloc--has-children:not(.sidebar__bloc--active)').on('click', function (cible) {
    if (!$(cible.target).closest('.sidebar__sublink a').length) {
      $(this).find('.sidebar__sublinks').slideToggle()
      $(this).toggleClass('sidebar__bloc--opened')
      if ($(this).hasClass('sidebar__bloc--opened')) {
        $(this)
          .find('.sidebar__sublinks ul li')
          .each(function (i) {
            let itemList = $(this)
            setTimeout(function () {
              itemList.toggleClass('sidebar__sublink--animate')
            }, 175 * i)
          })
      } else {
        $(this)
          .find('.sidebar__sublinks ul li')
          .each(function () {
            let itemList = $(this)
            itemList.removeClass('sidebar__sublink--animate')
          })
      }
    }
  })
}

function starRating() {
  var rating_input = $('#rating-input')
  $('.star_rating')
    .on('mouseenter', function () {
      var rating = $(this).index() + 1
      $('.star_rating').each(function (index) {
        if (index < rating) {
          $(this).removeClass('grey-lighter').addClass('purple')
        } else {
          $(this).removeClass('purple').addClass('grey-lighter')
        }
      })
    })
    .on('mouseleave', function () {
      $('.star_rating').each(function (index) {
        if (index < rating_input.val()) {
          $(this).removeClass('grey-lighter').addClass('purple')
        } else {
          $(this).removeClass('purple').addClass('grey-lighter')
        }
      })
    })
    .on('click', function (rating) {
      var rating = $(this).index() + 1
      rating_input.val(rating)
    })
}

// Function to reload page when filter date year changed
function reloadOnFilterDateChange() {
  $('#filter_date_year').on('change', function () {
    let url = new URL(window.location)
    url.searchParams.set('date', $(this)[0].value)
    window.location = url
  })
}

hamburger_nav()
/* */
table_dropdown_function()
//boxvisu_modal();
tab()
tablist()
fadeOut_alert()
fixed_maxHeight_div()
slide_tabs()
dropdown_toggle()
inbox()
/* */
table_boxvisu_function()
form_general()
/*table_border_cell();*/
preview_upload_image()
scrollLastMessageIntoView()
initToggleExternalConversationActionsIfWindowClosed()
show_hide_password()
/* 2021 */
notifs()
table_to_single()
toggle_accordeon()
magnificPopup()
/* Function counter characters sms*/
charactersCounterSms()
previewInputPhoto()
// This function is for change the true/false toggle
switchToggleTrueFalse()
// This function is for change the true/false toggle when we click on a link
switchToggleThroughLink()
// This function add a style hover
add_style_hover()
// filter precheckin questions thanks to kind //
displayPrecheckinQuestions()
// flatpick for Date & Time inputs //
loadFlatpickr()
// let timeInput = document.querySelector('.arrival-option-time');
// if (timeInput) {
//   minHour = timeInput.dataset.minhour;
//   maxHour = timeInput.dataset.maxhour;
//   loadFlatpickr(minHour, maxHour);
// } else {
//   loadFlatpickr();
// }
// set min max hours for arrival options when window is loaded
// setBoundedHours();
// OTA Accounts actions
ota_accounts()
openSubMenu()
starRating()
reloadOnFilterDateChange()

$(window).on('resize', function () {
  fixed_maxHeight_div()
  //boxvisu_modal();
  /*table_border_cell();*/
})
